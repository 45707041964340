import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroImage from "../components/HeroImage"
import AdventureDetails from "../components/AdventureDetails"
import TestimonialsSlider from "../components/TestimonialsSlider"

const adventure = props => {
  return (
    <Layout>
      <SEO
        title={props.data.adventure.acf._dow_meta_title}
        description={props.data.adventure.acf._dow_meta_description}
        metaImg={props.data.adventure.acf._dow_meta_image.localFile.publicURL}
        location={props.location.pathname}
      />
      <HeroImage
        imgFluid={
          props.data.adventure.acf._dow_image.localFile.childImageSharp.fluid
        }
        displayLogo={"no"}
        adventure={true}
      />
      <AdventureDetails
        price={props.data.adventure.acf._dow_price}
        title={props.data.adventure.acf._dow_title}
        execrpt={props.data.adventure.acf._dow_excerpt}
        points={props.data.adventure.acf._dow_adventure_points}
        bookLink={props.data.adventure.acf._dow_book_link}
        icon={props.data.adventure.acf._dow_fly_icon}
        calendly={props.data.adventure.acf._dow_calendly}
      />
      <TestimonialsSlider />
    </Layout>
  )
}

export const query = graphql`
  query adventureQuery($slug: String!) {
    adventure: wordpressWpAdventure(slug: { eq: $slug }) {
      acf {
        _dow_meta_title
        _dow_meta_description
        _dow_meta_image {
          localFile {
            publicURL
          }
        }

        _dow_title
        _dow_adventure_points {
          point
        }
        _dow_excerpt
        _dow_fly_icon
        _dow_price
        _dow_book_link
        _dow_calendly
        _dow_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

export default adventure
