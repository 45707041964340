import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { InlineWidget } from "react-calendly"

import {
  medWrapper,
  buttonOne,
  colors,
  bodyCopy,
  headlineOne,
  fonts,
} from "../Utilities"
import FlyIconOne from "../elements/FlyIconOne"
import FlyIconTwo from "../elements/FlyIconTwo"
import FlyIconThree from "../elements/FlyIconThree"

const AdventureDetailsSection = styled.section`
  padding: 0 0 5rem;

  .details-wrapper {
    ${medWrapper};
  }

  .dets-meta {
    align-self: flex-start;
    position: relative;
    width: 100%;
    padding: 5rem;
    background: ${colors.colorTertiary};
    text-align: center;
    z-index: 999999;

    @media (min-width: 768px) {
      width: calc(50% - 4rem);
      margin-top: -5rem;
      margin-right: 4rem;
    }

    @media (min-width: 1025px) {
      padding: 5rem 2.5rem 7.5rem;
    }

    .adventure-icon {
      width: 100%;
      max-width: 12.5rem;
      margin: 0 auto 2rem;
      padding: 0 1rem;
    }

    &__price {
      p {
        ${bodyCopy};
        color: ${colors.white};
        font-weight: bold;
      }
    }

    &__calendly {
      width: 100%;
      margin: auto;
    }

    &__buttons {
      a {
        ${buttonOne};
        display: block;
        max-width: 25rem;
        margin: 0 auto;
        border: solid 0.1rem ${colors.colorPrimary};
        box-shadow: -1rem -1rem 0 0 ${colors.colorPrimary};
        color: ${colors.colorPrimary};

        &:hover {
          color: ${colors.colorAccent};
          border: solid 0.1rem ${colors.colorAccent};
          box-shadow: -1rem -1rem 0 0 ${colors.colorAccent};
        }

        &:first-of-type {
          margin-bottom: 2.5rem;
        }
      }
    }
  }

  .dets-points {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(50%);
    }

    h2 {
      ${headlineOne};
      color: ${colors.colorPrimary};
    }

    &__excerpt {
      padding: 0 0 2.5rem;
      p {
        ${bodyCopy};
      }
    }

    ul {
      li {
        ${bodyCopy};
        display: block;
        position: relative;
        margin-bottom: 1.5rem;
        margin-left: 3.5rem;
        padding-left: 1.5rem;

        &::before {
          position: absolute;
          top: 0.9rem;
          left: 0;
          font-family: ${fonts.fontAwesome};
          font-size: 0.4em;
          content: "\f111";
        }
      }
    }

    .booking-remember {
      ${bodyCopy};
      color: ${colors.colorTertiary};

      a {
        ${bodyCopy};
        color: ${colors.colorTertiary};
        font-weight: 900;

        &:hover {
          color: ${colors.colorSecondary};
        }
      }
    }
  }

  .details-button {
    margin-top: 5rem;

    a {
      ${buttonOne};
    }
  }
`

const AdventureDetails = ({
  price,
  title,
  execrpt,
  points,
  bookLink,
  icon,
  calendly,
}) => {
  let flyIcon = false
  if (icon === "one") {
    flyIcon = <FlyIconOne />
  } else if (icon === "two") {
    flyIcon = <FlyIconTwo />
  } else if (icon === "three") {
    flyIcon = <FlyIconThree />
  }

  return (
    <AdventureDetailsSection>
      <div className="details-wrapper">
        <div className="dets-meta">
          {flyIcon && <div className="adventure-icon">{flyIcon}</div>}
          <div className="dets-meta__price">
            <p>Price: {price}</p>
          </div>
          <div className="dets-meta__calendly">
            <InlineWidget
              url={calendly}
              styles={{
                height: "1000px",
              }}
            />
          </div>
          {/* <div className="dets-meta__buttons">
            <a target="_blank" rel="noopener noreferrer" href={bookLink}>
              Check Availability & Book
            </a>
          </div> */}
        </div>

        <div className="dets-points">
          <div>
            <h2>{title}</h2>
          </div>
          <div
            className="dets-points__excerpt"
            dangerouslySetInnerHTML={{
              __html: execrpt,
            }}
          />
          <div>
            <ul>
              {points.map((point, index) => {
                return <li key={index}>{point.point}</li>
              })}
            </ul>
            <p className="booking-remember">
              &#42; Remember to confirm scheduled booking date on this page
              after you authorized the payment with PayPal. Make
              sure you click the blue "confirm schedule event" button on this
              page. You will then receive a confirmation email right away. If
              you do not get a confirmation email, please contact us right away.{" "}
              <a href="mailto: driftoutwestflyfishing@gmail.com">
                driftoutwestflyfishing@gmail.com
              </a>
            </p>
          </div>
        </div>
        <div className="details-button">
          <Link to="/adventures">Back To Adventures</Link>
        </div>
      </div>
    </AdventureDetailsSection>
  )
}

export default AdventureDetails
